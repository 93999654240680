import React, { Component } from "react";
import {
  PageHeader,
  PromoSpan,
} from "../../../components/StyledComponent/StyledComponents";
import MUIButton from "../../../components/MUIButton";
import { SomethingWentWrong } from "../../../assets/svg";
import { Dialog } from "@mui/material";

interface ErrorBoundaryStateType {
  hasError: boolean;
}

interface ErrorBoundaryPropType {
  fallback?: any;
  children?: any;
  history?: any;
}

export default class ErrorBoundary extends Component<
  ErrorBoundaryPropType,
  ErrorBoundaryStateType
> {
  constructor(props: ErrorBoundaryPropType) {
    super(props);

    this.state = { hasError: false };
    this.onTryAgain = this.onTryAgain.bind(this);
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    console.error(error);
  }

  onTryAgain() {
    this.props.history.goBack();
    this.setState((prev: ErrorBoundaryStateType) => ({
      hasError: !prev.hasError,
    }));
  }

  render() {
    if (this.state.hasError) {
      return (
        <Dialog open={this.state.hasError} disableEscapeKeyDown fullScreen>
          <div className="d-flex justify-content-center align-items-center w-100 min-h-100 user-select-none">
            <div className="d-flex align-items-center justify-content-center flex-column gap-1">
              <SomethingWentWrong style={{ width: "20vw", height: "30vh" }} />
              <PageHeader className="mt-0">
                Oops! Something Went Wrong
              </PageHeader>
              {/* <PromoSpan>
              We apologize for the inconvenience, but it seems that we're
              experiencing technical difficulties at the moment.
            </PromoSpan> */}
              <PromoSpan className="m-0 mb-2">
                We apologize for the inconvenience, but it seems that we're
                experiencing technical difficulties at the moment.
              </PromoSpan>
              <MUIButton label="Go Back" onClick={this.onTryAgain} />
              <PromoSpan className="mt-4">
                If you think this is an error, contact our support team at&nbsp;
                {window.location.host === "manage.instafogo.in" ? (
                  <a href="mailto:support@instafogo.in" className="color-black">
                    support@instafogo.in
                  </a>
                ) : (
                  <a href="mailto:support@ordrio.com" className="color-black">
                    support@ordrio.com
                  </a>
                )}
              </PromoSpan>
            </div>
          </div>
        </Dialog>
      );
    }

    return this.props.children;
  }
}
