const ON_RECEIVE_NOTIFICATION = "on-push-notification";
const ON_TAKE_NEXT = "on-take-next";
const ON_LOAD_GET = "get-notification-onload";
const SAVE_LOAD_NOTIFICATION = "save-loaded-notification";
const ON_ALL_LOAD = "on-all-notification-load";
const READ_ALL_NOTIFICATIONS = "read-all-notification";
const READ_ALL_NOTIFICATIONS_PUT = "on-read-all-put";
const READ_NOTIFICATION_PUT = "react-my-notification-put";
const READ_NOTIFICATION = "read-my-notification";

const CLEAR_NOTIFICATIONS_PUT = "on-clear-success";
const CLEAR_NOTIFICATIONS = "clear-notifications";

export {
  ON_RECEIVE_NOTIFICATION,
  ON_TAKE_NEXT,
  ON_LOAD_GET,
  ON_ALL_LOAD,
  SAVE_LOAD_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
  READ_NOTIFICATION_PUT,
  READ_ALL_NOTIFICATIONS_PUT,
  CLEAR_NOTIFICATIONS_PUT,
CLEAR_NOTIFICATIONS
};
