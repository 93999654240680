import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import Languages from "./language/reducers";
import Notification from "./notifications/reducers";

export default combineReducers({
  Auth,
  Layout,
  Languages,
  Notification,
});
