import React, { Fragment } from "react";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutTypes } from "../constants/layout";
import { RootState } from "../redux/store";

import DefaultLayout from "../layouts/Default";
import VerticalLayout from "../layouts/Vertical";
import DetachedLayout from "../layouts/Detached";
import HorizontalLayout from "../layouts/Horizontal/";
import TwoColumnLayout from "../layouts/TwoColumn/";

import {
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
} from "./index";
import { useGlobalContext } from "../App";

interface RoutesProps {}

const Routes = (props: RoutesProps) => {
  const { layout } = useSelector((state: RootState) => ({
    layout: state.Layout,
  }));

  const { enabledModules }: any = useGlobalContext();

  const getLayout = () => {
    let layoutCls = TwoColumnLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_VERTICAL:
        layoutCls = VerticalLayout;
        break;
      default:
        layoutCls = TwoColumnLayout;
        break;
    }
    return layoutCls;
  };

  let Layout = getLayout();

  return (
    <Fragment>
      <HashRouter>
        <Switch>
          <Route path={publicProtectedFlattenRoutes.map((r: any) => r["path"])}>
            <DefaultLayout {...props} layout={layout}>
              <Switch>
                {publicProtectedFlattenRoutes.map(
                  (route: any, index: number) => {
                    return (
                      !route.children && (
                        <route.route
                          key={index}
                          path={route.path}
                          roles={route.roles}
                          exact={route.exact}
                          component={route.component}
                        />
                      )
                    );
                  }
                )}
                <Route
                  path="*"
                  render={() => <Redirect to="/apps/dashboard" />}
                />
              </Switch>
            </DefaultLayout>
          </Route>

          <Route
            path={authProtectedFlattenRoutes
              .filter(
                (path) =>
                  !path.children &&
                  (!path.isPaid || enabledModules.includes(path.key))
              )
              .map((r: any) => r["path"])}
          >
            <Layout {...props}>
              <Switch>
                {authProtectedFlattenRoutes
                  .filter(
                    (path) =>
                      !path.children &&
                      (!path.isPaid || enabledModules.includes(path.key))
                  )
                  .map((route: any, index: number) => {
                    return (
                      <route.route
                        key={index}
                        path={route.path}
                        roles={route.roles}
                        exact={route.exact}
                        component={route.component}
                      />
                    );
                  })}
                <Route path="*" render={() => <Redirect to="/404" />} />
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </HashRouter>
    </Fragment>
  );
};

export default Routes;
