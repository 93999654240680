import {
  ON_RECEIVE_NOTIFICATION,
  ON_TAKE_NEXT,
  SAVE_LOAD_NOTIFICATION,
  ON_ALL_LOAD,
  READ_ALL_NOTIFICATIONS_PUT,
  READ_NOTIFICATION_PUT,
  CLEAR_NOTIFICATIONS_PUT,
} from "./constants";

interface actionType {
  type: string;
  payload: any;
}

const notification: any = {
  list: [],
  count: 0,
  allLoaded: false,
  loading: true,
};

function Notification(state = notification, action: actionType) {
  switch (action.type) {
    case ON_RECEIVE_NOTIFICATION:
      return {
        ...state,
        list: [action.payload, ...state.list],
        count: state.count + 1,
      };
    case ON_TAKE_NEXT:
      return {
        ...state,
        list: [...state.list, ...action.payload],
        allLoaded: action.payload.length < 10,
      };
    case SAVE_LOAD_NOTIFICATION: {
      return {
        ...action.payload,
        allLoaded: action.payload.list.length < 10,
        loading: false,
      };
    }
    case READ_ALL_NOTIFICATIONS_PUT: {
      let list = state.list.map((item: any) => ({
        ...item,
        notification_read: 0,
      }));

      return {
        ...state,
        list,
        count: 0,
      };
    }
    case READ_NOTIFICATION_PUT: {
      let list = [...state.list];
      let index = list.findIndex(
        (notification: any) => notification.id === action.payload
      );
      list[index].notification_read = 0;

      return { ...state, list };
    }
    case CLEAR_NOTIFICATIONS_PUT: {
      return {
        ...state,
        list: [],
        count: 0,
      };
    }
    case ON_ALL_LOAD: {
      return { ...state, allLoaded: true };
    }
    default:
      return state;
  }
}

export default Notification;
