import styled from "@emotion/styled";
import { FC } from "react";
import { Button as CustButton } from "react-bootstrap";

const PromoHeader = styled.span`
  color: black;
  font-family: LegendRegular;
  font-size: 1.2rem;
  font-weight: 500;
`;

const SalesHeader = styled.span`
  color: black;
  font-family: LegendRegular;
  font-size: 18px;
  font-weight: 500;
`;

const SalesTitle = styled.span`
  color: black;
  font-size: 34px;
  font-weight: 500;
`;

const SalesSubTitle = styled.span`
  color: black;
  font-size: 27px;
  font-weight: 500;
`;

const ProductTitle = styled.span`
  color: black;
  font-size: 15px;
  font-weight: 500;
  font-family: LegendRegular;
`;

const BrandTitle = styled.span`
  color: black;
  font-size: 14px;
  font-weight: 500;
  font-family: LegendRegular;
`;

const BrandPrice = styled.span`
  color: black;
  font-size: 11px;
  font-weight: 500;
  font-family: LegendRegular;
`;

const AnalyticsTitle = styled.span`
  color: black;
  font-size: 20px;
  font-weight: 500;
  font-family: LegendMedium;
`;

const AnalyticsNumber = styled.span`
  color: black;
  font-size: 33px;
  font-weight: 500;
`;

const StyledP = styled.p`
  color: black;
  font-family: Lexend;
  font-weight: 500;
  margin: 0;
  font-size: 12px;
`;
const PromoSpan = styled.span`
  color: #b4b4b4;
  font-family: Lexend;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 1rem;
`;

const PromoNormal = styled.p`
  font-family: LegendRegular;
  font-size: medium;
  color: black;
  margin-bottom: 0;
`;

const SpanHeader = styled.span`
  color: black;
  font-family: Lexend;
  font-size: 0.9rem;
  font-weight: 600;
`;
const Span = styled.span`
  color: black;
  font-family: Lexend;
  font-size: 0.75rem;
  font-weight: 500;
`;

const HR = styled.hr`
  color: #f1f5f9;
  background: #f0f0f0;
  height: 0.1rem;
  margin: 0;
`;

const SpanLite = styled.span`
  font-family: Lexend;
  font-size: 14px;
`;

const PageHeader = styled.label`
  color: black;
  font-family: LegendMedium;
  font-size: 1.2rem;
  font-weight: 500;
`;

const InputTitle = styled.label`
  color: black;
  font-family: "LegendRegular";
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 0.75rem;
`;

interface ButtonProps {
  disabled?: boolean;
  onClick?(e?: any): void;
  title: any;
  className?: string;
  type?: any;
  onMouseDown?(e: any): void;
  theme?: string;
  fontSize?: string;
}

const Button: FC<ButtonProps> = ({
  title,
  onClick,
  disabled,
  className,
  type,
  onMouseDown,
  fontSize,
  theme = "black",
}) => {
  const themes: any = {
    black: "bg-black color-white border-black",
    white: "bg-white color-black border-black",
  };
  return (
    <CustButton
      style={{
        border: "1px solid black",
        color: theme != "white" ? "white" : "black",
        fontSize: fontSize || "0.8rem",
        background: theme != "white" ? "black" : "white",
      }}
      className={`d-flex justify-content-center font-legend px-4 rounded-0-5 w-100 ${className} ${themes[theme]}`}
      onClick={onClick}
      disabled={disabled}
      type={type}
      onMouseDown={onMouseDown}
    >
      <div className="text-Regular">{title}</div>
    </CustButton>
  );
};

export {
  PromoHeader,
  SalesHeader,
  SalesTitle,
  SalesSubTitle,
  ProductTitle,
  BrandTitle,
  BrandPrice,
  AnalyticsTitle,
  AnalyticsNumber,
  PromoSpan,
  PromoNormal,
  SpanHeader,
  Span,
  HR,
  SpanLite,
  Button,
  PageHeader,
  InputTitle,
  StyledP,
};
