import { FC } from "react";
import { Form, InputGroup } from "react-bootstrap";

interface inputProps {
  placeholder?: string;
  name?: string;
  pattern?: string;
  title?: string;
  label?: string;
  className?: string;
  type?: string;
  value?: string | number;
  onChange?(e: any): void;
  onKeyDown?(e: any): void;
  hasIcon?: boolean;
  disabled?: any;
  icon?: any;
  required?: boolean;
  max?: number | string | undefined;
  min?: number | string | undefined;
  acceptOnlyString?: boolean;
  float?: boolean;
  fieldInvalid?: boolean;
}

const Input: FC<inputProps> = ({
  placeholder,
  name,
  pattern,
  title,
  label,
  type,
  value,
  className,
  onChange = (e) => console.log(e),
  hasIcon,
  disabled,
  icon = "₹",
  required = false,
  max,
  min,
  onKeyDown,
  acceptOnlyString,
  float,
  fieldInvalid,
}) => {
  const handleInput = (e: any) => {
    if (
      acceptOnlyString &&
      !/[a-z]/i.test(e.target.value.split("")?.[e.target.value.length - 1])
    ) {
      return;
    }
    onChange(e);
  };
  return (
    <Form.Group className="w-100">
      {label && (
        <Form.Label
          className={`label-Name text-Regular mb-2 ${
            className?.includes("color") ? "" : "color-black"
          } ${className}`}
        >
          {label}
        </Form.Label>
      )}

      <InputGroup
        className="w-100 d-flex flex-nowrap"
        style={{
          borderRight: "none !important",
        }}
      >
        {hasIcon && (
          <InputGroup.Text
            className="color-black"
            id="basic-addon1"
            style={{
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              borderRight: "none !important",
              backgroundColor: "white",
            }}
          >
            {icon}
          </InputGroup.Text>
        )}

        <Form.Control
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          onFocus={(e: any) => {
            e.target.select();
          }}
          style={{
            border: `1px solid ${!hasIcon && fieldInvalid ? "red" : "#D8D8D8"}`,
            borderRadius: hasIcon ? "0px" : "8px",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
            color: !hasIcon && fieldInvalid ? "red" : "black",
          }}
          className="text-Regular :focus{border-color: '#000'}"
          type={type}
          value={value && value}
          onChange={handleInput}
          onKeyDown={onKeyDown}
          required={required}
          autoComplete="off"
          max={max}
          min={min}
          pattern={pattern}
          title={title}
          onWheel={(e: any) => {
            e.target.blur();
          }}
          step={float ? 0.1 : 1}
        />
      </InputGroup>
    </Form.Group>
  );
};

export default Input;
