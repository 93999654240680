import React, { useState, forwardRef, useImperativeHandle } from "react";
import Lottie from "react-lottie";
import errorGif from "../assets/gif/notifyerrorgif.json";
import successGif from "../assets/gif/notifysuccessgif.json";
import warningGif from "../assets/gif/warning.json";

const notificationProps: any = {
  success: {
    gif: {
      loop: true,
      autoplay: true,
      animationData: successGif,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    styles: { backgroundColor: "#f3ffee", border: "1px solid #84d65c" },
  },
  warning: {
    gif: {
      loop: true,
      autoplay: true,
      animationData: warningGif,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    styles: { backgroundColor: "#ffe4e4", border: "1px solid #ffd466" },
  },
  error: {
    gif: {
      loop: true,
      autoplay: true,
      animationData: errorGif,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    },
    styles: { backgroundColor: "#FFE7B0", border: "1px solid red" },
  },
};

const NotificationPopup = (props: any, ref: any) => {
  const [notification, setNotification]: any = useState({
    status: false,
    message: "",
    type: "",
    actionHandler: null,
  });

  const removeNotification = () => {
    if (notification?.actionHandler) {
      notification?.actionHandler();
    }

    setNotification({
      ...notification,
      status: false,
      message: "",
      type: "",
    });
  };

  useImperativeHandle(ref, () => ({
    showSuccess(msg = "", actionHandler = null) {
      setNotification({
        ...notification,
        status: true,
        message: msg,
        type: "success",
        actionHandler: actionHandler,
      });
      setTimeout(() => {
        setNotification({
          ...notification,
          status: false,
          message: "",
          type: "",
        });
      }, 3000);
    },
    showError(msg = "") {
      setNotification({
        ...notification,
        status: true,
        message: msg,
        type: "error",
        actionHandler: (l: any) => l,
      });
      setTimeout(() => {
        if (notification?.actionHandler) {
          notification?.actionHandler();
        }
        setNotification({
          ...notification,
          status: false,
          message: "",
          type: "",
          actionHandler: null,
        });
      }, 3000);
    },
    showWarning(msg = "") {
      setNotification({
        ...notification,
        status: true,
        message: msg,
        type: "warning",
        actionHandler: (l: any) => l,
      });
      setTimeout(() => {
        if (notification?.actionHandler) {
          notification?.actionHandler();
        }
        setNotification({
          ...notification,
          status: false,
          message: "",
          type: "",
          actionHandler: null,
        });
      }, 3000);
    },
  }));

  return (
    <div
      className={`${
        notification?.status == true
          ? "text-Regular p-2 toast-display"
          : "toast-no-display"
      }`}
      style={{
        width: "25%",
        zIndex: "999999999999",
        position: "fixed",
        borderRadius: "7px",
        bottom: "5%",
        right: "1%",
        ...notificationProps?.[notification?.type]?.styles,
      }}
    >
      <div className="cursor-pointer" id="react-gif pe-2">
        {notification?.status && (
          <Lottie
            options={notificationProps?.[notification?.type]?.gif}
            height={25}
            width={25}
          />
        )}
      </div>
      <div className="color-black ps-2" style={{ paddingTop: "3px" }}>
        <div className="">{notification?.message}</div>
      </div>
      <div
        className="cursor-pointer ps-2"
        style={{ paddingTop: "3px", marginLeft: "auto" }}
        onClick={removeNotification}
      >
        <div>
          <i className="uil-times h4" />
        </div>
      </div>
    </div>
  );
};

export default forwardRef(NotificationPopup);
