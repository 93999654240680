import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

//toast notifications
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

// apicore
import { APICore, setAuthorization } from "../../helpers/api/apiCore";

// helpers
import {
  login as loginApi,
  logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  resetPassword as resetPasswordApi,
} from "../../helpers";

// actions
import { authApiResponseSuccess, authApiResponseError } from "./actions";

// constants
import { AuthActionTypes } from "./constants";

interface UserData {
  payload: {
    username: string;
    password: string;
    fullname: string;
    timezone: string;
    email: string;
    resetPassword: string;
    confirmPassword: string;
    token: string;
    domain: string;
    name: string;
    phone: string;
    isNumber: boolean;
    from: string;
    data: Array<[]>;
  };
  type: string;
}

const api = new APICore();
const timezone = new Date().getTimezoneOffset();

/**
 * Login the user
 * @param {*} payload - username and password
 */

const Broadcast = new BroadcastChannel("auth_change");

function onLogin() {
  Broadcast.postMessage(JSON.stringify({ type: "login" }));
}

function* login({
  payload: { email, password, timezone, data, from },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { email, password, timezone });

    if (response) {
      const user = response?.data;
      if (user.status == false) {
        // notificationRef.current.showError(user?.message);
        yield put(
          authApiResponseError(AuthActionTypes.LOGIN_USER, "Wrong Credentials")
        );
        toast.error(user?.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        onLogin();
        if (user?.data?.role === "SA" && user?.ref_type != 0) {
          localStorage.setItem("default_location", user.default_location);
        } else {
          localStorage.setItem("default_location", user.default_location);
        }
        localStorage.setItem("ref", user.ref);
        // NOTE - You can change this according to response format from your api
        api.setLoggedInUser(user);
        setAuthorization(response?.data?.token);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
      }
    } else {
      yield put(
        authApiResponseError(AuthActionTypes.LOGIN_USER, "Wrong Credentials")
      );
      toast.error("Invalid email or password", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* socialLogin({
  payload: { email, password, timezone },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { email, password, timezone });
    if (response) {
      const user = response?.data;
      if (!user.status) {
        toast.error(user?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.success(user?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (user?.data?.role === "SA" && user?.ref_type != 0) {
          localStorage.setItem("default_location", "0");
        } else {
          localStorage.setItem("default_location", user.default_location);
        }
        localStorage.setItem("ref", user.ref);

        // NOTE - You can change this according to response format from your api
        api.setLoggedInUser(user);
        setAuthorization(user["token"]);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
      }
    } else {
      toast.error("Invalid email or password", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}
/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    yield call(logoutApi);
    api.setLoggedInUser(null);

    Broadcast.postMessage(JSON.stringify({ type: "logout" }));

    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    localStorage.clear();
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

/**
 * signup
 */
function* signup({
  payload: { name, email, password, phone, isNumber },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, {
      name,
      email,
      password,
      phone,
      isNumber,
    });

    const user = response.data;
    if (user.message === "User Already Registered") {
      toast.error("Email or phone already Exist", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      onLogin();
      api.setLoggedInUser(user);
      setAuthorization(user.token);
      yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user.data));
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Forget Password
 */
function* forgotPassword({
  payload: { email, domain },
}: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { email, domain });
    const user = response?.data;
    if (user.message) {
      toast.success("Password reset link sent to your Email", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      yield put(
        authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, user.message)
      );
    } else {
      toast.error("Enter a valid email address", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

/**
 * Reset password
 */
function* resetPassword({
  payload: { resetPassword, confirmPassword, token },
}: UserData): SagaIterator {
  try {
    const response = yield call(resetPasswordApi, {
      resetPassword,
      confirmPassword,
      token,
    });
    if (response) {
      const user = response.data;
      console.log(response);
      yield put(authApiResponseSuccess(AuthActionTypes.RESET_PASSWORD, user));
      toast.success("Password has been changed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error("Password not changed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.RESET_PASSWORD, error));
  }
}

function changeLocation() {
  window.location.pathname = "auth/login";
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchSocialLogin(): any {
  yield takeEvery(AuthActionTypes.SOCIAL_LOGIN, socialLogin);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchresetPassword(): any {
  yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchSocialLogin),
    fork(watchForgotPassword),
    fork(watchresetPassword),
  ]);
}

export default authSaga;
