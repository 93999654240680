import { notificationService } from "../../Config/axiosBaseUrls";

async function getNotifications(cursor?: string) {
  return await notificationService.post(
    "/api/v1/notification/get-notification",
    { cursor }
  );
}

async function readAllNotification() {
  try {
    return await notificationService.post("/api/v1/notification/mark-all-read");
  } catch (err) {
    console.error(err);
  }
}

async function readNotification(id: string) {
  try {
    return await notificationService.post("/api/v1/notification/mark-read", {
      id,
    });
  } catch (err) {
    console.error(err);
  }
}

async function clearAllNotifications() {
  try {
    return await notificationService.get(
      "/api/v1/notification/clear-panel-notification"
    );
  } catch (err) {
    console.error(err);
  }
}

export {
  getNotifications,
  readAllNotification,
  readNotification,
  clearAllNotifications,
};
