import { FC } from "react";
import { styled } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

interface MUIButtonProps extends LoadingButtonProps {
  label?: string;
  children?: React.ReactNode;
  buttonTheme?: "light" | "dark";
}

const MUIButton: FC<MUIButtonProps> = ({
  label = "Save",
  disableRipple = false,
  buttonTheme = "dark",
  children,
  ...props
}) => {
  const CustomButton = styled(LoadingButton)<ButtonProps>(({ theme }: any) => ({
    color: theme.palette.getContrastText(
      buttonTheme === "dark" ? "#000" : "#fff"
    ),
    minWidth: 0,
    backgroundColor: buttonTheme === "dark" ? "#000" : "#fff",
    fontWeight: 400,
    fontFamily: "LegendRegular",
    border: buttonTheme === "dark" ? "none" : "1px solid rgb(239, 239, 239)",
    textTransform: "none",
    padding: "6px 3rem",
    wordWrap: "normal",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: buttonTheme === "dark" ? "#000" : "#fff",
      border: buttonTheme === "dark" ? "none" : "1px solid #000",
    },
    "& .Mui-disabled": {
      backgroundColor: buttonTheme === "dark" ? "#fff" : "#fff",
      color: "white",
    },
    "& .MuiLoadingButton-loadingIndicator": {
      color: buttonTheme !== "dark" ? "#000" : "#fff",
    },
    // "& .Mui-disabled": {
    //   color: "white !important",
    // },
  }));

  return (
    <CustomButton {...props} disableRipple={disableRipple}>
      {children ? children : label}
    </CustomButton>
  );
};

export default MUIButton;
