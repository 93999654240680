import { NoInternet as NoInternetSVG } from "../../../assets/svg";
import {
  PageHeader,
  PromoSpan,
} from "../../../components/StyledComponent/StyledComponents";
import MUIButton from "../../../components/MUIButton";

import { useState } from "react";
import { Dialog } from "@mui/material";

const NoInternet = () => {
  const [isChecking, setChecking] = useState(false);
  const [open, setOpen] = useState(false);

  window.addEventListener("online", (e) => {
    setOpen(false);
  });

  window.addEventListener("offline", (e) => {
    setOpen(true);
  });

  async function checkAndRedirect() {
    await new Promise((resolve) => {
      setChecking(true);
      setTimeout(() => {
        resolve(true);
      }, 1000);
    });
    if (window.navigator.onLine) {
      setOpen(false);
    } else {
      setChecking(false);
    }
  }

  return (
    <Dialog open={open} disableEscapeKeyDown fullScreen>
      <div className="d-flex justify-content-center align-items-center w-100 min-h-100 user-select-none">
        <div className="d-flex align-items-center justify-content-center flex-column gap-1">
          <NoInternetSVG style={{ width: "20vw", height: "30vh" }} />
          <PageHeader className="mt-0">Oops! No Internet Connection</PageHeader>
          <PromoSpan>
            It seems that your device is not able to connect to the internet.
          </PromoSpan>
          <PromoSpan className="m-0 mb-2">
            Please verify that you have a working internet connection and try
            again.
          </PromoSpan>
          <MUIButton
            label="Try Again"
            onClick={checkAndRedirect}
            loading={isChecking}
          />
          <PromoSpan className="mt-4">
            If you think this is an error, contact our support team at&nbsp;
            {window.location.host === "manage.instafogo.in" ? (
              <a href="mailto:support@instafogo.in" className="color-black">
                support@instafogo.in
              </a>
            ) : (
              <a href="mailto:support@ordrio.com" className="color-black">
                support@ordrio.com
              </a>
            )}
          </PromoSpan>
        </div>
      </div>
    </Dialog>
  );
};

export default NoInternet;
