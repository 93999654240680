import React, { lazy } from "react";
import { Route, RouteProps } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Root from "./Root";
import CreateInventory from "../pages/apps/Settings/components/Inventory/createInventory";
import RoutesInfoFull from "../pages/apps/subscription/dashboard/RoutesInfoFull";
import TopSellingProductsFullInfo from "../pages/apps/subscription/dashboard/TopSellingProductFullInfo";

const RechargeFullInfo = lazy(
  () => import("../pages/apps/subscription/dashboard/RechargeFullInfo")
);
const PausedFullInfos = lazy(
  () => import("../pages/apps/subscription/dashboard/PausedFullInfo")
);
const SubscriptionAddress = lazy(
  () => import("../pages/apps/Customer/Subscription/SubscriptionAddress")
);
const LowWalletFullInfo = lazy(
  () => import("../pages/apps/subscription/dashboard/LowWalletFullInfo")
);
const RouteFullInfo = lazy(
  () => import("../pages/apps/subscription/dashboard/RoutesInfoFull")
);
const TopSellingProductFullInfo = lazy(
  () => import("../pages/apps/subscription/dashboard/TopSellingProductFullInfo")
);
const RouteWiseSaleFullInfo = lazy(
  () => import("../pages/apps/subscription/dashboard/RouteWiseSaleFullInfo")
);
const LowStockProductsFullInfo = lazy(
  () => import("../pages/apps/subscription/dashboard/LowStockProductFullInfo")
);
const Promocode = lazy(
  () => import("../pages/apps/Promotion/Promocode/Promocode")
);
const ReferAndEarn = lazy(
  () => import("../pages/apps/Promotion/Promocode/ReferAndEarn/ReferandEarn")
);
const ViewCustomer = lazy(
  () => import("../pages/apps/Customer/CusomerPreview")
);

const CreatePromocode = lazy(
  () => import("../pages/apps/Promotion/Promocode/CreatePromocode")
);
const Filters = lazy(() => import("../pages/apps/products/Filters/Filters"));

const AddProduct = lazy(
  () => import("../pages/apps/products/Product/Products/AddProduct")
);

const CreateModuleAddOn = lazy(
  () => import("../pages/apps/AddOn/CreateModuleAddOn")
);
const ProductRoutes = lazy(
  () => import("../pages/apps/products/ProductRoutes")
);
const SetUpModules = lazy(() => import("../pages/apps/AddOn/SetUpModules"));
const NotFound = lazy(() => import("../pages/apps/ErrorPages/NotFound"));
const NoInternet = lazy(() => import("../pages/apps/ErrorPages/NoInternet"));
const Category = lazy(() => import("../pages/apps/products/Category/Category"));
const InsightsRoutes = lazy(
  () => import("../pages/apps/Insights/InsightsRoutes")
);
const ProductDashbaord = lazy(
  () => import("../pages/apps/Insights/Products/productDashaboard")
);
const AnalyticsInsightsDashboard = lazy(
  () => import("../pages/apps/Insights/Analytics/analyticsDashboard")
);
const SalesReports = lazy(() => import("../pages/apps/Insights/SalesReport"));
const Sales = lazy(() => import("../pages/apps/Insights"));
const Delivery = lazy(() => import("../pages/apps/Dispatch/Delivery/Delivery"));
const Loyalty = lazy(() => import("../pages/apps/Promotion/Loyalty/Loyalty"));
const Login = lazy(() => import("../pages/auth/Login"));
const DeliveryPartner = lazy(
  () =>
    import(
      "../pages/apps/Dispatch/DeliveryPartners/DeliveryPartner/DeliveryPartner"
    )
);
const DispatchBatch = lazy(
  () => import("../pages/apps/Dispatch/DispatchBatch/DispatchBatch")
);
const BatchList = lazy(
  () => import("../pages/apps/Dispatch/DispatchBatch/BatchList")
);
const PartnerDetails = lazy(
  () => import("../pages/apps/Dispatch/DeliveryPartners/PartnerDetails")
);
const ProductTable = lazy(
  () => import("../pages/apps/products/Product/ProductTable/ProductTable")
);

const Design = lazy(() => import("../pages/apps/HomeDesign/Design"));
const ProductBox = lazy(
  () => import("../pages/apps/products/ProductBox/ProductBox")
);

const Logout = lazy(() => import("../pages/auth/Logout"));
const Confirm = lazy(() => import("../pages/auth/Confirm"));
const ForgetPassword = lazy(() => import("../pages/auth/ForgetPassword"));
//const Register = lazy(() => import("../pages/auth/Register"));
//const newRegister = lazy(() => import("../pages/auth/newRegister"));
const newRegister = lazy(() => import("../pages/auth/newRegister"));
const LockScreen = lazy(() => import("../pages/auth/LockScreen"));
const ResetPassword = lazy(() => import("../pages/auth/ResetPassword"));

// landing
const CustomersTable = lazy(
  () => import("../pages/apps/Customer/Customers/CustomersTable")
);
const CustomerDetails = lazy(
  () => import("../pages/apps/Customer/CustomerDetails/CustomerDetails")
);
const CustomerForm = lazy(
  () => import("../pages/apps/Customer/CustomerForm/CustomerForm")
);
const AddSubscription = lazy(
  () =>
    import(
      "../pages/apps/subscription/dashboard/Add Subscription/AddSubscription"
    )
);

// dashboard
const EcommerceDashboard = lazy(() => import("../pages/dashboard/Ecommerce/"));
const AnalyticsDashboard = lazy(() => import("../pages/dashboard/Analytics/"));

const marketPlace = lazy(
  () => import("../pages/apps/Merchants/MerchantDashBoard/index")
);
const Order = lazy(() => import("../pages/apps/Orders/Order"));
const createOrder = lazy(() => import("../pages/apps/Orders/createOrder"));
const CreateInventoryBoard = lazy(
  () => import("../pages/apps/Settings/components/Inventory/createInventory")
);
const OrdersViewTable = lazy(
  () => import("../pages/apps/Orders/OrderTable/OrderViewTable")
);
const OrdersFilterView = lazy(
  () => import("../pages/apps/Orders/orderFilterTable")
);
const createCustomer = lazy(
  () => import("../pages/apps/Customer/CreateCustomer")
);
const ViewOrder = lazy(() => import("../pages/apps/Orders/viewOrder"));
const OrdersView = lazy(() => import("../pages/apps/Orders/orderView"));
const OrdersFile = lazy(
  () =>
    import(
      "../pages/apps/Merchants/MerchantDashBoard/components/Web12801/OrdersFile"
    )
);

const OrdersEdit = lazy(() => import("../pages/apps/Orders/orderViewEdit"));

const store = lazy(
  () => import("../pages/apps/Merchants/CreateMerchants/merchantTable")
);

// - Product
const Categories = lazy(() => import("../pages/apps/products/Categories"));
const AddProductsCatalogue = lazy(
  () => import("../pages/apps/catalogue/Product/AddProductCatalogue")
);
const EditProductsCatalogue = lazy(
  () => import("../pages/apps/catalogue/Product/EditCatalogueProduct")
);

const ProductImport = lazy(
  () => import("../pages/apps/products/BulkUpdate/ProductImport")
);

const BulkTable = lazy(
  () => import("../pages/apps/products/BulkUpdate/BulkTable")
);

const EditProducts = lazy(
  () => import("../pages/apps/catalogue/Product/EditProduct")
);

// - Add Product
const AddProducts = lazy(
  () => import("../pages/apps/catalogue/Product/AddProduct")
);

const deliveryslot = lazy(
  () => import("../pages/apps/Dispatcher/DeliverySlots/deliverySlots")
);

const editDeliverySlot = lazy(
  () => import("../pages/apps/Dispatcher/DeliverySlots/editDeliverySlot")
);

const editslot = lazy(
  () => import("../pages/apps/Dispatcher/DeliverySlots/editSlots")
);

// Modules

const modules = lazy(() => import("../pages/apps/modules/modules"));

const Banner = lazy(() => import("../pages/apps/Promotion/Banner/banner"));

const createBanner = lazy(
  () => import("../pages/apps/Promotion/Banner/createBanner")
);

const featuredItems = lazy(
  () => import("../pages/apps/DesignOLD/featuredItems")
);
const DesignComponent = lazy(() => import("../pages/apps/DesignOLD/design"));

const Membership = lazy(
  () => import("../pages/apps/Promotion/Membership/Membership")
);

const DeliveryLocation = lazy(
  () => import("../pages/apps/Dispatcher/DispatcherCreation/deliveryLocation")
);

const newLocation = lazy(
  () => import("../pages/apps/Dispatcher/DispatcherCreation/newLocation")
);

const EditNewLocation = lazy(
  () => import("../pages/apps/Dispatcher/DispatcherCreation/newLocation")
);

const deliveryBoy = lazy(
  () => import("../pages/apps/Dispatcher/DeliveryPersons/deliveryBoys")
);

const newDeliveryBoy = lazy(
  () => import("../pages/apps/Dispatcher/DeliveryPersons/addDeliveryBoy")
);

const pages = lazy(() => import("../pages/apps/Settings/Pages/pages"));

const createNewPages = lazy(
  () => import("../pages/apps/Settings/Pages/createNewPage")
);

const createStore = lazy(
  () => import("../pages/apps/Merchants/CreateMerchants/createMerchant")
);

const business = lazy(
  () => import("../pages/apps/Merchants/CreateMerchants/editMerchant")
);

const draw = lazy(() => import("../pages/apps/Merchants/CreateMerchants/draw"));

const editStoreSettings = lazy(
  () => import("../pages/apps/Merchants/OtherFiles/storeSettings")
);

const ManageAccount = lazy(() => import("../pages/apps/Profile/ManageAccount"));
const OrdrioMoney = lazy(() => import("../pages/apps/Profile/OrdrioMoney"));
const accounts = lazy(
  () => import("../pages/apps/Merchants/OtherFiles/accounts")
);

const commission = lazy(
  () => import("../pages/apps/Merchants/OtherFiles/commercials")
);

const overall = lazy(
  () =>
    import(
      "../pages/apps/Merchants/MerchantDashBoard/components/Web12801/OverAll"
    )
);
const prodandcat = lazy(
  () =>
    import(
      "../pages/apps/Merchants/MerchantDashBoard/components/Web12801/ProdAndCat"
    )
);

const orderfile = lazy(
  () =>
    import(
      "../pages/apps/Merchants/MerchantDashBoard/components/Web12801/OrdersFile"
    )
);
const BillingFile = lazy(
  () =>
    import(
      "../pages/apps/Merchants/MerchantDashBoard/components/Web12801/BillingFile"
    )
);

const LoginForm = lazy(() => import("../pages/LoginPanel/Login"));

const PurchaseComponent = lazy(() => import("../pages/apps/Purchase/Purchase"));

const Inwards = lazy(() => import("../pages/apps/Purchase/Inwards/Inwards"));
const CreateInwards = lazy(
  () => import("../pages/apps/Purchase/Inwards/createInwards")
);

const Inventory = lazy(
  () => import("../pages/apps/products/Product/Inventory/Inventory")
);

const Subscription = lazy(
  () => import("../pages/apps/subscription/Subscription")
);

const InventorySystem = lazy(
  () => import("../pages/apps/Settings/components/Inventory/inventory")
);

const CustomDuration = lazy(
  () => import("../pages/apps/Dispatch/CustomDuration/CustomDuration")
);

const Settings = lazy(() => import("../pages/apps/Settings/Settings"));

const Brands = lazy(() => import("../pages/apps/products/Brands/Brands"));

const Notification = lazy(
  () => import("../pages/apps/notification/Notifications")
);

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  key?: string;
  parentKey?: string;
  isPaid?: boolean;
  component?: RouteProps["component"];
  route?: any;
  exact?: RouteProps["exact"];
  icon?: string;
  header?: string;
  roles?: string[];
  ref_type?: number[];
  children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: () => <Root />,
  route: Route,
};

// dashboards
const moduleRoutes: RoutesProps = {
  path: "/apps/modules",
  name: "Modules",
  isPaid: false,
  icon: "airplay",
  header: "Navigation",
  children: [
    {
      path: "/apps/modules",
      name: "Ecommerce",
      component: modules,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    // {
    //   path: "/apps/not",
    //   name: "Ecommerce",
    //   component: Maintenance,
    //   isPaid: false,
    //   route: PrivateRoute,
    //   roles: ["admin-2"],
    //   ref_type: [2],
    // },
    {
      path: "/apps/module/create-module",
      name: "Product",
      isPaid: false,
      component: CreateModuleAddOn,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/module/setup/:ref?",
      name: "Product",
      isPaid: false,
      component: SetUpModules,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

const dashboardRoutes: RoutesProps = {
  path: "/apps/dashboard",
  name: "Dashboards",
  icon: "airplay",
  header: "Navigation",
  key: "dashboard",
  isPaid: false,
  route: PrivateRoute,
  component: EcommerceDashboard,
};

const PurchaseAppRoutes: RoutesProps = {
  path: "",
  name: "Purchase",
  component: PurchaseComponent,
  key: "purchase",
  isPaid: true,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  children: [
    {
      path: "/apps/purchase/inwards",
      name: "purchase_inwards",
      component: PurchaseComponent,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/invoice",
      name: "invoice",
      component: PurchaseComponent,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/create-order",
      name: "Create_purchase",
      component: CreateInwards,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/create-inwards",
      name: "purchase_create_inwards",
      component: CreateInwards,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/create-invoice",
      name: "purchase_create_invoice",
      component: CreateInwards,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/edit-invoice/:purchase_id",
      name: "edit_invoice",
      component: CreateInwards,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/edit-order/:purchase_id",
      name: "Purchase_edit_invoice",
      component: CreateInwards,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/edit-inwards/:purchase_id",
      name: "Purchase_create_orders",
      component: CreateInwards,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/edit-purchase-inwards/:purchase_id",
      name: "edit_purchase_orders",
      component: CreateInwards,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/editInwardsInvoice/:purchase_id",
      name: "edit_purchase_orders",
      component: CreateInwards,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase",
      name: "Purchase",
      component: PurchaseComponent,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },

    {
      path: "/apps/purchase/returns",
      name: "Purchase_return",
      component: Inwards,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/purchase/indent",
      name: "Purchase_indent",
      component: PurchaseComponent,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

const CustomerSubscriptionAddress: RoutesProps = {
  path: "/apps/subscriptionAddress/:subscription/:cusId",
  name: "Customer",
  component: SubscriptionAddress,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};

const DashBoardLowWallet: RoutesProps = {
  path: "/apps/LowWalletFullInfo",
  name: "LowWalletFullInfo",
  component: LowWalletFullInfo,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};
const DashBoardPausedData: RoutesProps = {
  path: "/apps/PausedFullInfos",
  name: "PausedFullInfo",
  component: PausedFullInfos,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};
const DashBoardRechargeInfo: RoutesProps = {
  path: "/apps/RechargeFullinfo",
  name: "RechargeFullInfo",
  component: RechargeFullInfo,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};
const DashBoardRoute: RoutesProps = {
  path: "/apps/RouteInfo",
  name: "RouteFullInfo",
  component: RoutesInfoFull,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};

const DashBoardLowStock: RoutesProps = {
  path: "/apps/LowStockProductsFullInfo",
  name: "LowStockProductsFullInfo",
  component: LowStockProductsFullInfo,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};
const DashBoardTopProducts: RoutesProps = {
  path: "/apps/TopSellingProductsFullInfo",
  name: "TopSellingProductsFullInfo",
  component: TopSellingProductsFullInfo,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};
const DashBoardRouteWiseSale: RoutesProps = {
  path: "/apps/RouteWiseSale",
  name: "RouteWiseSale",
  component: RouteWiseSaleFullInfo,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};
const DashBoardAddSubscription: RoutesProps = {
  path: "/apps/AddSubscription",
  name: "AddSubscription",
  component: AddSubscription,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};

const CustomerAppRoutes: RoutesProps = {
  path: "/apps/Customer",
  name: "Customer",
  component: ViewCustomer,
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  children: [
    {
      path: "/apps/Customer",
      name: "Customer",
      component: CustomersTable,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/add-customer/",
      name: "add-customer",
      component: CustomerForm,
      route: PrivateRoute,
    },
    {
      path: "/apps/edit-customer/:id",
      name: "edit-customer",
      component: CustomerForm,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/customer-details/:id/:route",
      name: "edit-customer",
      component: CustomerDetails,
      route: PrivateRoute,
      roles: ["admin-2"],
    },
    {
      path: "/apps/CreateCustomer",
      name: "CreateCustomer",
      component: createCustomer,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

const profileRoutes: RoutesProps = {
  path: "/apps/profile/:id?",
  name: "profile",
  component: ManageAccount,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  isPaid: false,
  children: [
    {
      path: "/apps/profile/manage-account",
      name: "profile",
      component: ManageAccount,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/profile/ordrio-money",
      name: "profile",
      component: OrdrioMoney,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

const StoreAppRoutes: RoutesProps = {
  path: "/apps/marketPlace",
  name: "Customer",
  component: marketPlace,
  route: PrivateRoute,
  isPaid: false,
  children: [
    {
      path: "/apps/marketPlace/:id",
      name: "store",
      component: marketPlace,
      route: PrivateRoute,
      // roles: ["admin-2"],
      // ref_type: [2],
    },
    {
      path: "/apps/createStore",
      name: "createStore",
      component: createStore,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/merchants",
      name: "store",
      component: store,
      route: PrivateRoute,
      roles: ["admin-2", "admin-1", "SA-0", "admin-0"],
      ref_type: [2],
    },
    {
      path: "/apps/merchants",
      name: "store",
      component: store,
      route: PrivateRoute,
      roles: ["admin-2", "admin-1", "SA-0", "admin-0"],
      ref_type: [2],
    },
    {
      path: "/apps/marketPlace",
      name: "marketPlace",
      component: marketPlace,
      route: PrivateRoute,
      // roles: ["admin-2"],
      // ref_type: [2],
    },
    {
      path: "/apps/business/:id",
      name: "business",
      component: business,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/drawMap",
      name: "drawMap",
      component: draw,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/storeSettings/:id",
      name: "storeSettings",
      component: editStoreSettings,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/accounts/:id",
      name: "accounts",
      component: accounts,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/commission/:id",
      name: "commission",
      component: commission,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/marketplace/overall",
      name: "overall",
      component: overall,
      route: PrivateRoute,
      roles: ["admin-2,admin"],
      ref_type: [2],
    },
    {
      path: "/apps/marketplace/products-and-categories",
      name: "prodandcat",
      component: prodandcat,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/marketplace/orders",
      name: "orderfile",
      component: orderfile,
      route: PrivateRoute,
      roles: ["admin-2, admin"],
      ref_type: [2],
    },
    {
      path: "/apps/marketplace/billing",
      name: "billingfile",
      component: BillingFile,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

const deliveyAppRoutes: RoutesProps = {
  path: "/apps/deliveryLocation",
  name: "deliveryLocation",
  component: DeliveryLocation,
  route: PrivateRoute,
  isPaid: false,
  roles: ["admin-2"],
  ref_type: [2],
  children: [
    {
      path: "/apps/deliveryLocation",
      name: "deliveryLocation",
      component: DeliveryLocation,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },

    {
      path: "/apps/deliveryBoys",
      name: "deliveryBoys",
      component: deliveryBoy,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/addDeliveryBoy",
      name: "addDeliveryBoy",
      component: newDeliveryBoy,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/editDeliveryBoy/:id",
      name: "addDeliveryBoy",
      component: newDeliveryBoy,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/deliverySlots",
      name: "deliverySlots",
      component: deliveryslot,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/editDeliverySlot",
      name: "editDeliverySlot",
      component: editDeliverySlot,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/editslot",
      name: "editslot",
      component: editslot,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

const MarketingAppRoutes: RoutesProps = {
  path: "/apps/membership/design",
  name: "banner",
  isPaid: false,
  component: DesignComponent,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  children: [
    {
      path: "/apps/design",
      name: "featuredItems",
      isPaid: false,
      component: DesignComponent,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/redesign",
      name: "featuredItems",
      isPaid: false,
      component: Design,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/featuredItems",
      name: "featuredItems",
      component: featuredItems,
      // component: DesignComponent,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },

    {
      path: "/apps/addMembership",
      name: "Create Membership",
      component: Membership,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

//PromotionRoutes
const promotionRoutes: RoutesProps = {
  path: "/apps/promotion/",
  name: "promotion",
  isPaid: false,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  children: [
    {
      path: "/apps/loyalty/:route?",
      name: "Loyalty Program",
      key: "loyalty",
      isPaid: false,
      component: Loyalty,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/promocode",
      name: "PromoCode",
      key: "promocode",
      isPaid: true,
      component: Promocode,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    // {
    //   path: "/apps/referandearn",
    //   name: "Refer and Earn",
    //   key: "referandearn",
    //   isPaid: true,
    //   component: ReferAndEarn,
    //   route: PrivateRoute,
    //   roles: ["admin-2"],
    //   ref_type: [2],
    // },
    {
      path: "/apps/create-promocode",
      name: "createPromocode",
      key: "promocode",
      isPaid: true,
      component: CreatePromocode,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/edit-promocode/:id",
      name: "editPromocode",
      key: "promocode",
      isPaid: true,
      component: CreatePromocode,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/membership/:route?",
      name: "Membership",
      key: "membership",
      isPaid: true,
      component: Membership,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

//Dispatcher Routes
const dispatcherRoute: RoutesProps = {
  path: "/apps/dispatcher/",
  name: "Dispatch",
  key: "dispatcher",
  isPaid: false,
  // route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  children: [
    {
      path: "/apps/dispatch/custom-duration/:route?",
      name: "Custom Duration",
      key: "custom-duration",
      component: CustomDuration,
      isPaid: true,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/dispatch/delivery/:route?",
      name: "Delivery",
      key: "delivery",
      component: Delivery,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/dispatch/delivery-partner",
      name: "Delivery Partner",
      key: "delivery-partner",
      component: DeliveryPartner,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2, admin"],
      ref_type: [2],
    },
    {
      path: "/apps/dispatch/dispatch-batch",
      name: "Dispatch Batch",
      key: "dispatch-batch",
      component: DispatchBatch,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/dispatch/batch-list",
      name: "BatchList",
      key: "batch-list",
      component: BatchList,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/dispatch/partner-details/:id?/:route?",
      name: "Delivery Partner",
      key: "delivery-partner",
      component: PartnerDetails,
      isPaid: false,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

//order
const ordersAppRoutes: RoutesProps = {
  path: "/apps/orders",
  name: "Orders",
  component: Order,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],

  icon: "Box",
  children: [
    {
      path: "/apps/orders",
      name: "Order",
      component: Order,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/orders-views/:filter",
      name: "OrdersViewTable",
      component: OrdersViewTable,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },

    {
      path: "/apps/view-orders/:id",
      name: "vieworders",
      component: OrdersView,
      // component: ViewOrder,
      route: PrivateRoute,
      roles: ["admin, admin-2"],
      ref_type: [2],
    },

    {
      path: "/apps/edit-orders/:id",
      name: "vieworders",
      component: OrdersView,
      // component: ViewOrder,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/create-order",
      name: "createOrder",
      component: createOrder,
      // component: PosOrder,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

//products
const productsAppRoutes: RoutesProps = {
  path: "/apps/products/:route?",
  name: "Products",
  key: "products",
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  icon: "package",
  children: [
    {
      path: "/apps/products/:route?",
      name: "Product",
      component: ProductRoutes,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/product/brands",
      name: "Product",
      component: Brands,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/product/product-box",
      name: "Product",
      component: ProductBox,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },

    {
      path: "/apps/product/filters",
      name: "Product",
      component: Filters,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/product/categories",
      name: "Categories",
      // component: Categories,
      component: Category,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/product/category/:id",
      name: "Categories",
      component: Categories,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/product/add-products",
      name: "AddProducts",
      component: AddProduct,
      route: PrivateRoute,
      roles: ["admin-2, admin"],
      ref_type: [2],
    },
    {
      path: "/apps/product/edit-product/:id",
      name: "EditProduct",
      component: AddProduct,
      route: PrivateRoute,
      roles: ["none"],
      ref_type: [-1],
      //
    },

    {
      path: "/apps/product/inventory",
      key: "inventory",
      name: "Inventory",
      // component: InventoryOLD,
      component: Inventory,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },

    {
      path: "/apps/product/custom-category/",
      key: "custom-category",
      name: "Custom Category",
      component: Category,
      isPaid: true,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/product/bulk-history",
      name: "BulkUpdateHistory",
      component: BulkTable,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/product/bulk-import",
      name: "BulkImport",
      component: ProductImport,
      route: PrivateRoute,
      roles: ["admin-2 , admin", "SA"],
      ref_type: [2],
    },
  ],
};

//Subscription
const subscriptionRoutes: RoutesProps = {
  path: "/apps/subscription/:route",
  name: "subscription",
  key: "subscription",
  isPaid: true,
  component: Subscription,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  // children: [
  //   {
  //     path: "/apps/add-products/",
  //     name: "add-products",
  //     component: AddProductSubs,
  //     route: PrivateRoute,
  //   },
  // ],
};

//Inventory
const inventoryRoutes: RoutesProps = {
  path: "/apps/inventory/damaged-entry",
  name: "inventory",
  key: "inventory",
  isPaid: true,
  component: InventorySystem,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};

const inventoryMainRoutes: RoutesProps = {
  path: "/apps/product/inventory",
  key: "inventory",
  name: "Inventory",
  // component: InventoryOLD,
  component: Inventory,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};

const inventoryAddRoutes: RoutesProps = {
  path: "/apps/create-inventory",
  name: "createInventory",
  component: CreateInventoryBoard,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};
const inventoryAddRoutesId: RoutesProps = {
  path: "/apps/create-inventory?:id",
  name: "createInventory",
  component: CreateInventoryBoard,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};

//Notification
const notificationRoutes: RoutesProps = {
  path: "/apps/notification/:route?",
  name: "notification",
  key: "notification",
  isPaid: false,
  component: Notification,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
};

//Catalogue
const catalogueAppRoutes: RoutesProps = {
  path: "/apps/catalogue/categories",
  name: "Catalog",
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  icon: "list",
  children: [
    {
      path: "/apps/catalogue/categories",
      name: "Categories",
      component: Category,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/catalogue/add-products",
      name: "AddProducts",
      component: AddProduct,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/catalogue/products",
      name: "Products",
      component: ProductTable,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/catalogue/edit-product/:id",
      name: "AddCategories",
      component: AddProduct,
      route: PrivateRoute,
      roles: ["admin-2 , admin", "SA"],
      ref_type: [1, 2],
    },

    {
      path: "/apps/product/edit-products/:id",
      name: "EditProduct",
      component: EditProducts,
      route: PrivateRoute,
      roles: ["none"],

      ref_type: [-1],
    },
    // {
    //   path: "/apps/product/edit-categories/:id/:type",
    //   name: "AddCategories",
    //   component: AddCatalogCategories,
    //   route: PrivateRoute,

    //
    // },
    // {
    //   path: "/apps/catalogue/add-subcategory/:id",
    //   name: "AddSubCategories",
    //   component: AddCatalogCategories,
    //   route: PrivateRoute,

    //
    // },
    // {
    //   path: "/apps/catalogue/add-subcategory/:parent_id",
    //   name: "AddSubCategories",
    //   component: AddCatalogCategories,
    //   route: PrivateRoute,

    //
    // },
  ],
};

// Insights Route
const insightsAppRoutes: RoutesProps = {
  path: "/apps/insights/:route?",
  name: "Sales",
  key: "sales",
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [2],
  icon: "package",
  children: [
    {
      path: "/apps/insights/:route?",
      name: "Sales",
      component: InsightsRoutes,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/insights/products",
      name: "Products",
      component: ProductDashbaord,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/insights/analytics",
      name: "Analytics",
      component: AnalyticsInsightsDashboard,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/insights/multi-branch",
      name: "Multi Branch",
      component: Sales,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
    {
      path: "/apps/insight/sales-report",
      name: "Sales",
      component: SalesReports,
      route: PrivateRoute,
      roles: ["admin-2"],
      ref_type: [2],
    },
  ],
};

//SettingRoutes
const settingRoutes: RoutesProps = {
  path: "/apps/settings",
  name: "settings",
  component: Settings,
  route: PrivateRoute,
  roles: ["admin-2"],
  ref_type: [1, 2],
  icon: "settings",
};

// const settingRoutes: RoutesProps = {
//   path: "/apps/settings",
//   name: "settings",
//   component: Settings,
//   route: PrivateRoute,
//   // roles: ["admin-2"],
//   // ref_type: [1, 2],
//   icon: "settings",
//   children: [
//     {
//       path: "/apps/settings",
//       name: "settings",
//       component: Settings,
//       route: PrivateRoute,
//       roles: ["admin-2", "admin-1"],
//       ref_type: [1, 2],

//       icon: "settings",
//     },
//     // {
//     //   path: "/apps/settingPage",
//     //   name: "settingPage",
//     //   component: settingPage,
//     //   route: PrivateRoute,
//     //   roles: ["admin-1", "admin-2"],
//     //   ref_type: [1, 2],
//     //   icon: "settings",
//     // },
//     {
//       path: "/apps/userTable",
//       name: "userTable",
//       component: UserTable,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "settings",
//     },
//     {
//       path: "/apps/basicSettings",
//       name: "basicSettings",
//       component: basicSetting,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "settings",
//     },
//     {
//       path: "/apps/pages",
//       name: "pages",
//       component: pages,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "page",
//     },
//     {
//       path: "/apps/createNewPages",
//       name: "createNewPages",
//       component: createNewPages,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "",
//     },
//     {
//       path: "/apps/EditPage/:id",
//       name: "createNewPages",
//       component: createNewPages,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "",
//     },

//     {
//       path: "/apps/taxses",
//       name: "taxses",
//       component: taxses,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "",
//     },
//     {
//       path: "/apps/socials",
//       name: "socials",
//       component: socials,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "",
//     },
//     {
//       path: "/apps/languagesTable",
//       name: "LanguagesTable",
//       component: LanguagesTable,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],
//       icon: "",
//     },
//     {
//       path: "/apps/CreateLanguage",
//       name: "CreateLanguage",
//       component: CreateLanguage,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],
//       icon: "",
//     },
//     {
//       path: "/apps/userAccounts",
//       name: "userAccounts",
//       component: userAccounts,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "",
//     },

//     {
//       path: "/apps/userAccounts/:id",
//       name: "userAccounts",
//       component: userAccounts,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [1, 2],

//       icon: "",
//     },
//     {
//       path: "/apps/payments",
//       name: "payments",
//       component: payments,
//       route: PrivateRoute,
//       roles: ["admin-2"],
//       ref_type: [2],

//       icon: "",
//     },
//   ],
// };

const appRoutes = [
  moduleRoutes,
  profileRoutes,
  StoreAppRoutes,
  settingRoutes,
  productsAppRoutes,
  insightsAppRoutes,
  ordersAppRoutes,
  PurchaseAppRoutes,
  CustomerAppRoutes,
  MarketingAppRoutes,
  subscriptionRoutes,
  inventoryRoutes,
  inventoryMainRoutes,
  inventoryAddRoutes,
  inventoryAddRoutesId,
  dispatcherRoute,
  promotionRoutes,
  deliveyAppRoutes,
  notificationRoutes,
  catalogueAppRoutes,
  CustomerSubscriptionAddress,
  DashBoardLowWallet,
  DashBoardRoute,
  DashBoardLowStock,
  DashBoardTopProducts,
  DashBoardRouteWiseSale,
  DashBoardPausedData,
  DashBoardAddSubscription,
  DashBoardRechargeInfo,
  // importExportRoute,
];

// ui
/* const uiRoutes: RoutesProps = {
    path: '/components',
    name: 'Components',
    icon: 'package',
    header: 'UI Elements',
    children: [
   
        {
            path: '/icons',
            name: 'Icons',
            children: [
              
            ],
        },
        {
            path: '/forms',
            name: 'Forms',
            children: [
             
            ],
        },
     
        {
            path: '/tables',
            name: 'Tables',
            children: [
             
            ],
        },
        {
            path: '/maps',
            name: 'Maps',
            children: [
              
            ],
        },
    ],
};
 */
// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/auth/register",
    name: "newRegister",
    component: newRegister,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/auth/reset-password/:token",
    name: "Reset Password",
    component: ResetPassword,
    route: Route,
  },
  {
    path: "/404",
    name: "Page Not Found",
    component: NotFound,
    route: Route,
  },
  {
    path: "/no-internet",
    name: "Page Not Found",
    component: NoInternet,
    route: Route,
  },
];

// public routes
/* const otherPublicRoutes: RoutesProps[] = [
    {
        path: '/landing',
        name: 'landing',
        component: Landing,
        route: Route,
    },
     {

        path: '/maintenance',
        name: 'Maintenance',
        component: Maintenance,
        route: Route,
    }, 
    {
        path: '/error-404',
        name: 'Error - 404',
        component: Error404,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: Error500,
        route: Route,
    }, 
]; */

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });

  return flatRoutes;
};

// All routes
const authProtectedRoutes = [
  rootRoute,
  dashboardRoutes,
  /*  productRoutes,  */ ...appRoutes /* extrapagesRoutes */ /* uiRoutes */,
];
const publicRoutes = [...authRoutes /* ...otherPublicRoutes */];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
