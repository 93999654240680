import React, { useState, useEffect, Fragment } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import Input from "../../../../components/Input";
import { ArrowLeft } from "../../../../assets/svg";
import { useHistory, useLocation } from "react-router";
import { subscriptionService } from "../../../../Config/axiosBaseUrls";
import { SpanHeader, Span } from "../../Customer/components/StyledComponents";

interface RoutesInfoFull {}

const RoutesInfoFull = ({}: RoutesInfoFull) => {
  const history = useHistory();

  const [subAll, setSubAll] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const location = useLocation<{ day: any }>();
  const dayData = location.state?.day || localStorage.getItem("dayData");
  useEffect(() => {
    if (location.state?.day) {
      localStorage.setItem("dayData", location.state.day);
    }
  }, [location.state?.day]);

  useEffect(() => {
    if (location.state?.day) {
      localStorage.setItem("dayData", location.state.day);
    }
  }, [location.state?.day]);

  const handleClick = () => {
    history.push("/apps/subscription/dashboard");
  };

  function fetchData() {
    subscriptionService
      .get(`/api/v1/subscription/view-all?frequency=${dayData}&group_by=routes`)
      .then((response: any) => {
        setSubAll(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  useEffect(fetchData, []);

  const filteredData = subAll.filter((item: any) =>
    item?._id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Col style={{ marginTop: "30px" }} lg={13}>
      <div
        className="d-flex justify-content-center min-h-screen"
        style={{ background: "rgb(248, 248, 248)" }}
      >
        <Card className="w-70 border-0 mt-3">
          <Card.Body>
            <Fragment>
              <Span
                style={{
                  fontFamily: "Lexend",
                  fontSize: "15px",
                  fontWeight: "bolder",
                  color: "#28282B",
                }}
                onClick={handleClick}
              >
                <span>
                  <ArrowLeft style={{ marginRight: 5 }} />
                </span>{" "}
                Orders by Routes
              </Span>
              <Row className="d-flex justify-content-between">
                <Col lg={12} md={6} sm={12} className="mt-3">
                  <Input
                    hasIcon
                    icon={<i className="uil-search" />}
                    placeholder="Search by route name"
                    name="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </Col>
              </Row>
              <Col>
                <table
                  className="table mx-auto"
                  style={{
                    width: "100%",
                    marginTop: "35px",
                    fontFamily: "Lexend",
                    fontSize: "14px",
                    fontWeight: "normal",
                    color: "#28282B",
                    lineHeight: "25px",
                  }}
                >
                  <thead
                    className="text-left"
                    style={{
                      backgroundColor: "#F0F0F0",
                      border: "1px solid #F0F0F0",
                    }}
                  >
                    <tr>
                      <th style={{ width: "43%" }}>
                        <div>Route Name</div>
                      </th>
                      <th style={{ width: "0%", textAlign: "center" }}>
                        <div>Orders</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {filteredData.length > 0 ? (
                      filteredData.map((items: any) => (
                        <tr className="border-bottom " key={items?._id}>
                          <td>{items?._id}</td>
                          <td style={{ textAlign: "center" }}>
                            {items?.count}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2} style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Col>
            </Fragment>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};

export default RoutesInfoFull;
