import React from "react";
import { Redirect } from "react-router-dom";

import { APICore } from "../helpers/api/apiCore";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
const Root = () => {
  const api = new APICore();
  const { auth }: any = useSelector((state: RootState) => ({
    auth: state?.Auth?.user,
  }));
  const getRootUrl = () => {
    let url: string = "auth/login";

    // check if user logged in or not and return url accordingly
    if (api.isUserAuthenticated() === false) {
      url = "auth/login";
    } else {
      url = "";
      auth.data.role === "admin" && auth?.ref_type === 2
        ? (url = "apps/marketPlace")
        : (url = "");
    }
    return url;
  };

  const url = getRootUrl();

  return <Redirect to={`/${url}`} />;
};

export default Root;
