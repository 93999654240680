import React, { createContext, useContext, useState, ReactNode, ReactElement } from 'react';

// Define the context interface
interface DispatchContextType {
    orderType: string;
    setOrderType: any;
    orderBatchTableId: any,
    setOrderBatchTableId: any
}

// Create the context
const DispatchContext = createContext<DispatchContextType | undefined>(undefined);

// Create a provider component
interface DispatchProviderProps {
    children: ReactNode;
}

const DispatchProvider: React.FC<DispatchProviderProps> = ({ children }: DispatchProviderProps) => {

    const [orderType, setOrderType] = useState<any>("order");
    const [orderBatchTableId, setOrderBatchTableId] = useState<any>("");

    return (
        <DispatchContext.Provider value={{ orderType, setOrderType, orderBatchTableId, setOrderBatchTableId }}>
            {children}
        </DispatchContext.Provider>
    );
};

// Custom hook to use the context
const useDispatchContext = (): DispatchContextType => {
    const context = useContext(DispatchContext);
    if (!context) {
        throw new Error('useDispatchContext must be used within a DispatchProvider');
    }
    return context;
};

export { useDispatchContext, DispatchProvider, DispatchContext }