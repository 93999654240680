import React, { Fragment } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
  useHistory,
} from "react-router-dom";
import { APICore } from "../helpers/api/apiCore";
import NoInternet from "../pages/apps/ErrorPages/NoInternet";
import ErrorBoundary from "../pages/apps/ErrorPages/ErrorBoundary";

interface PrivateRouteProps {
  component: React.FunctionComponent<RouteProps>;
  roles?: string[];
  ref_type?: Number[];
  path?: string;
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */

const PrivateRoute = ({
  component: Component,
  roles,
  ref_type,
  path,
  ...rest
}: PrivateRouteProps) => {
  const api = new APICore();
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) => {
        if (!api.isUserAuthenticated()) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props["location"] },
              }}
            />
          );
        }

        const loggedInUser = api.getLoggedInUser();
        let user_role = `${loggedInUser?.data?.role}-${loggedInUser?.ref_type}`;

        // check if route is restricted by role

        // if (roles && loggedInUser && roles !== loggedInUser.data.role ) {

        if (roles?.includes(user_role)) {
          console.log("Yesss Blocked");
          // role not authorised so redirect to home page
          return <Redirect to={{ pathname: "/" }} />;
        }
        // authorised so return component

        // if (!window.navigator.onLine) {
        //   return <Redirect to="/no-internet" />;
        // }

        return (
          <ErrorBoundary history={history}>
            <Component {...props} />
          </ErrorBoundary>
        );
      }}
    />
  );
};

export default PrivateRoute;
