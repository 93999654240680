import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  getNotifications,
  readAllNotification,
  readNotification,
  clearAllNotifications,
} from "./api";
import {
  onLoadNotifiction,
  onLoadNext,
  onReadPut,
  onReadAllPut,
  onClearSuccess,
} from "./actions";
import {
  CLEAR_NOTIFICATIONS,
  ON_LOAD_GET,
  READ_ALL_NOTIFICATIONS,
  READ_NOTIFICATION,
} from "./constants";

function* getAllNotification({ payload: { cursor = "" } }: any): SagaIterator {
  try {
    const res = yield call(getNotifications, cursor);
    if (res?.data?.status) {
      if (cursor) {
        yield put(onLoadNext(res?.data?.data?.list));
      } else {
        yield put(onLoadNotifiction(res?.data?.data));
      }
    }
  } catch (err) {
    // console.log(err);
    yield put(onLoadNotifiction({ list: [], count: 0 }));
  }
}

function* readAllNotifications(): SagaIterator {
  try {
    const res = yield call(readAllNotification);
    if (res?.data?.status) {
      yield put(onReadAllPut());
    }
  } catch (err) {
    console.error(err);
  }
}

function* readNotifications({ payload: id }: any): SagaIterator {
  try {
    const res = yield call(readNotification, id);
    if (res?.data?.status) {
      yield put(onReadPut(id));
    }
  } catch (err) {
    console.error(err);
  }
}

function* clearAllNotification(): SagaIterator {
  try {
    const res = yield call(clearAllNotifications);

    if (res.data?.status) {
      yield put(onClearSuccess());
    }
  } catch (err) {
    console.error(err);
  }
}

function* watchGetNotification() {
  yield takeEvery(ON_LOAD_GET, getAllNotification);
}

function* watchReadAll() {
  yield takeEvery(READ_ALL_NOTIFICATIONS, readAllNotifications);
}

function* watchRead() {
  yield takeEvery(READ_NOTIFICATION, readNotifications);
}

function* watchClear() {
  yield takeEvery(CLEAR_NOTIFICATIONS, clearAllNotification);
}

export default function* notificationSaga() {
  yield all([
    fork(watchGetNotification),
    fork(watchReadAll),
    fork(watchRead),
    fork(watchClear),
  ]);
}
