import styled from "@emotion/styled";
import { Button } from "react-bootstrap";

const SpanHeader = styled.span`
  color: black;
  font-family: Lexend;
  font-size: 0.9rem;
  font-weight: 600;
`;
const Span = styled.span`
  color: black;
  font-family: Lexend;
  font-size: 0.75rem;
  font-weight: 500;
`;

const HR = styled.hr`
  color: #f1f5f9;
  background: #f1f5f9;
  height: 0.7rem;
  margin: 0;
`;

const SpanLite = styled.span`
  font-family: Lexend;
  font-size: 14px;
`;

export { SpanHeader, Span, HR, SpanLite };
