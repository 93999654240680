// eslint-disable-next-line react-hooks/exhaustive-deps
import {
  useRef,
  createContext,
  useContext,
  useEffect,
  useState,
  StrictMode,
} from "react";
import Routes from "./routes/Routes";
import "./assets/scss/Theme.scss";
import NotificationPopup from "./components/notificationPopup";
import { RootState } from "./redux/store";
import { useSelector } from "react-redux";
import { customerServiceApi } from "./Config/axiosBaseUrls";
import { useDispatch } from "react-redux";
import { logoutUser, setLanguagesOnLoad } from "./redux/actions";
import NoInternet from "./pages/apps/ErrorPages/NoInternet";
import { PaginationHelper } from "./components/Helpers/PaginationHelper";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DispatchProvider } from "./pages/apps/Dispatch/DispatchBatch/DispatchContext";

const client = new QueryClient();

const Pagination = new PaginationHelper();

export const AppContext: any = createContext(null);

export const useGlobalContext = () => {
  return useContext(AppContext);
};

const App = () => {
  const dispatch = useDispatch();
  const notificationRef: any = useRef();
  const [currency, setCurrency] = useState(() => {
    let getLocalCurrency = localStorage.getItem("currency");
    let parsedCurrecy = getLocalCurrency
      ? JSON.parse(getLocalCurrency)
      : {
        currency: "Indian Rupee",
        symbol: "₹",
      };

    return parsedCurrecy;
  });

  const [enabledModules, setEnabledModules] = useState(() => {
    let getLocal = localStorage.getItem("enabledModules");
    let modules = getLocal ? JSON.parse(getLocal) : [];

    if (Array.isArray(modules)) {
      return modules;
    }

    return [];
  });

  const { auth } = useSelector((state: RootState) => ({
    auth: state?.Auth?.user,
  }));

  const getEnabledModule = () => {
    if (auth) {
      customerServiceApi
        .get("api/v1/auth/enabled-modules")
        .then((res) => {
          let modules = res?.data?.ref_modules.map((item: any) => item.module);
          setEnabledModules(modules);
          dispatch(
            setLanguagesOnLoad({
              languages: res?.data?.languages,
              default: res?.data?.languages[0],
            })
          );

          localStorage.setItem(
            "languages",
            JSON.stringify(res?.data?.languages)
          );
          localStorage.setItem(
            "defaultLanguage",
            JSON.stringify(res?.data?.languages[0])
          );
          localStorage.setItem("enabledModules", JSON.stringify(modules));
        })
        .catch((err) => {
          let langs = localStorage.getItem("languages");
          let defaultLang = localStorage.getItem("defaultLanguage");
          dispatch(
            setLanguagesOnLoad({
              languages: langs ? JSON.parse(langs) : [],
              default: defaultLang ? JSON.parse(defaultLang) : "",
            })
          );
        });
    }
  };

  function onCurrencyChange(currency: string, symbol: string) {
    const changedCurrency = {
      currency,
      symbol,
    };

    localStorage.setItem("currency", JSON.stringify(changedCurrency));
    setCurrency(changedCurrency);
  }

  useEffect(() => {
    if (auth) {
      let refreshURL = "/api/v1/auth/refresh-token";

      let notifSubscription: any = localStorage.getItem(
        "notificationSubscription"
      );

      notifSubscription = notifSubscription
        ? JSON.parse(notifSubscription)
        : "";

      if (notifSubscription) {
        refreshURL =
          refreshURL +
          `?deviceId=${notifSubscription?.deviceId}&subscriptionID=${notifSubscription?.id}`;
      }
      customerServiceApi.get(refreshURL).then((res) => {
        if (!res?.data?.status) {
          dispatch(logoutUser());
        } else {
          const ValidationChannel = new BroadcastChannel(
            "subscription_validation"
          );

          ValidationChannel.postMessage(res?.data?.isSubscriptionValid);

          let data = res?.data?.ref;
          let currency = {
            currency: data?.currency,
            symbol: data?.currency_symbol,
          };
          localStorage.setItem("currency", JSON.stringify(currency));
          setCurrency(currency);
        }
      });
    }
  }, []);

  useEffect(getEnabledModule, [auth]);

  return (
    <StrictMode>
      <AppContext.Provider
        value={{
          notificationRef,
          location_id: auth?.data?.location_id,
          enabledModules: enabledModules,
          refreshEnabledModules: getEnabledModule,
          currency,
          onCurrencyChange,
          PaginationHelper: Pagination,
        }}
      >
        <DispatchProvider>
          <QueryClientProvider client={client}>
            <NoInternet />
            <NotificationPopup ref={notificationRef} />
            <Routes />
          </QueryClientProvider>
        </DispatchProvider>
      </AppContext.Provider>
    </StrictMode>
  );
};

export default App;
