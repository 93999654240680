// constants
import { AuthActionTypes } from "./constants";

export interface AuthActionType {
  type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET_PASSWORD
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER;

  payload: {} | string;
}

interface UserData {
  id: number;
  email: string;
  name: string;
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  role: string;
  token: string;
  domain: string;
  resetPassword: string;
  confirmPassword: string;
  phone: string;
}

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: UserData | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//login
export const loginUser = (email: string, password: string): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { email, password },
});

//logout
export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

//signup
export const signupUser = (
  name: string,
  email: string,
  password: string,
  phone: string,
  isNumber: boolean
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { name, phone, email, password, isNumber },
});

export const socialLogin = (
  email: string,
  first_name: string
): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { email, first_name },
});

//forgot password
export const forgotPassword = (
  email: string,
  domain: string
): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { email, domain },
});

//reset password
export const resetPassword = (
  resetPassword: string,
  confirmPassword: string,
  token: string
) => ({
  type: AuthActionTypes.RESET_PASSWORD,
  payload: { resetPassword, confirmPassword, token },
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
