import { UPDATE_LANGUAGE, CHANGE_DEFAULT, SET_ON_LOAD } from "./constants";

export function updateAllLangs(language: Array<any>) {
  return {
    type: UPDATE_LANGUAGE,
    payload: language,
  };
}

export function changeDefaultLanguage(language: any) {
  return {
    type: CHANGE_DEFAULT,
    payload: language,
  };
}

export function setLanguagesOnLoad(payload: any) {
  return {
    type: SET_ON_LOAD,
    payload,
  };
}
