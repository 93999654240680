import {
  ON_RECEIVE_NOTIFICATION,
  ON_TAKE_NEXT,
  ON_LOAD_GET,
  ON_ALL_LOAD,
  SAVE_LOAD_NOTIFICATION,
  READ_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS_PUT,
  READ_NOTIFICATION,
  READ_NOTIFICATION_PUT,
  CLEAR_NOTIFICATIONS_PUT,
  CLEAR_NOTIFICATIONS,
} from "./constants";

function getNotification(payload: string) {
  return { type: ON_LOAD_GET, payload };
}

function onPushNotification(payload: any) {
  return { type: ON_RECEIVE_NOTIFICATION, payload };
}

function onLoadNotifiction(payload: any) {
  return { type: SAVE_LOAD_NOTIFICATION, payload };
}

function getOnLoadNotifications(cursor?: any) {
  return { type: ON_LOAD_GET, payload: { cursor } };
}

function onLoadNext(payload: any) {
  return { type: ON_TAKE_NEXT, payload };
}

function onAllLoad() {
  return { type: ON_ALL_LOAD };
}

function onReadAll() {
  return { type: READ_ALL_NOTIFICATIONS, payload: "" };
}

function onReadAllPut() {
  return { type: READ_ALL_NOTIFICATIONS_PUT, payload: "" };
}

function onReadOne(id: string) {
  return { type: READ_NOTIFICATION, payload: id };
}

function onReadPut(id: string) {
  return { type: READ_NOTIFICATION_PUT, payload: id };
}

function onClear() {
  return { type: CLEAR_NOTIFICATIONS };
}

function onClearSuccess() {
  return {
    type: CLEAR_NOTIFICATIONS_PUT,
  };
}

export {
  getNotification,
  onPushNotification,
  onLoadNotifiction,
  onLoadNext,
  getOnLoadNotifications,
  onAllLoad,
  onReadAll,
  onReadOne,
  onReadPut,
  onReadAllPut,
  onClear,
  onClearSuccess,
};
