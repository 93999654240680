import { APICore } from "./apiCore";
import axios from "axios";
import { UserServiceApi } from "../../Config/axiosBaseUrls";

//timezone
const api = new APICore();

const timezone = "Asia/Kolkata";

// account
async function login(params: {
  email: string;
  password: string;
  timezone: string;
}) {
  try {
    return await UserServiceApi.post(`/api/v1/auth/login`, params);
  } catch (error) {}
}

function logout() {
  const baseUrl = "/logout/";
  return api.create(`${baseUrl}`, {});
}

async function signup(params: {
  name: string;
  email: string;
  password: string;
  phone: string;
  isNumber: boolean;
}) {
  const client = await axios.get("https://ipapi.co/json/");
  return await UserServiceApi.post(`/auth/register`, params);
}

async function forgotPassword(params: { email: string; domain: string }) {
  try {
    return await UserServiceApi.post(`/api/v1/auth/forget-password`, params);
  } catch (error) {}
}

async function resetPassword(params: {
  resetPassword: string;
  confirmPassword: string;
  token: string;
}) {
  try {
    return await UserServiceApi.post(`/reset-password`, params);
  } catch (error) {}
}

export { login, logout, signup, forgotPassword, resetPassword };
