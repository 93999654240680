import React, { useState, useEffect, Fragment } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router";
import { useLocation } from "react-router";
import Input from "../../../../components/Input";
import { ArrowLeft } from "../../../../assets/svg";
import { subscriptionService } from "../../../../Config/axiosBaseUrls";
import { SpanHeader, Span } from "../../Customer/components/StyledComponents";

interface RoutesInfoFull {}

const TopSellingProductsFullInfo = ({}: RoutesInfoFull) => {
  const history = useHistory();
  const [subAll, setSubAll] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const location = useLocation<{ day: any }>();

  const dayData = location.state?.day || localStorage.getItem("dayData");
  useEffect(() => {
    if (location.state?.day) {
      localStorage.setItem("dayData", location.state.day);
    }
  }, [location.state?.day]);

  const handleClick = () => {
    history.push("/apps/subscription/dashboard");
  };

  function fetchData() {
    subscriptionService
      .get(
        `/api/v1/subscription/view-all?frequency=${dayData}&group_by=products`
      )
      .then((response: any) => {
        setSubAll(response?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const filteredProducts = subAll.filter((item: any) =>
    item.product_name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <Col style={{ marginTop: "30px" }} lg={13}>
      <div
        className="d-flex justify-content-center min-h-screen"
        style={{ background: "rgb(248, 248, 248)" }}
      >
        <Card className="w-70 border-0 mt-3">
          <Card.Body>
            <Fragment>
              <Span
                style={{
                  fontFamily: "Lexend",
                  fontSize: "15px",
                  fontWeight: "bolder",
                  color: "#28282B",
                }}
                onClick={handleClick}
              >
                <span>
                  <ArrowLeft style={{ marginRight: 5 }} />
                </span>{" "}
                Top Selling Products
              </Span>
              <Row className="d-flex justify-content-between">
                <Col lg={12} md={6} sm={12} className="mt-3">
                  <Input
                    hasIcon
                    icon={<i className="uil-search" />}
                    placeholder="Search by product name"
                    name="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                </Col>
              </Row>
              <Col>
                {filteredProducts.length === 0 ? (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    No data available
                  </div>
                ) : (
                  <table
                    className="table mx-auto"
                    style={{
                      width: "100%",
                      marginTop: "35px",
                      fontFamily: "Lexend",
                      fontSize: "14px",
                      fontWeight: "normal",
                      color: "#28282B",
                      lineHeight: "25px",
                    }}
                  >
                    <thead
                      className="text-left"
                      style={{
                        backgroundColor: "#F0F0F0",
                        border: "1px solid #F0F0F0",
                      }}
                    >
                      <tr>
                        <th style={{ width: "63%" }}>
                          <div>Product Name</div>
                        </th>
                        <th style={{ width: "10%", textAlign: "left" }}>
                          <div>Quantity</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-left">
                      {filteredProducts.map((item: any) => (
                        <tr className="border-bottom" key={item.product_name}>
                          <td>{item?.product_name}</td>
                          <td>{item?.sale}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </Col>
            </Fragment>
          </Card.Body>
        </Card>
      </div>
    </Col>
  );
};

export default TopSellingProductsFullInfo;
