import { UPDATE_LANGUAGE, CHANGE_DEFAULT, SET_ON_LOAD } from "./constants";

let localStorageLangs = localStorage.getItem("languages");

const inititalState = {
  languages: localStorageLangs
    ? JSON.parse(localStorageLangs)
    : [{ id: "en", language: "English", language_code: "en" }],
  default: { id: "en", language: "English", language_code: "en" },
};

interface State {
  languages: Array<any>;
  default: any;
}

interface actionType {
  type: string;
  payload: any;
}

const Languages = (state: State = inititalState, action: actionType) => {
  switch (action.type) {
    case UPDATE_LANGUAGE:
      return { ...state, languages: action.payload };
    case CHANGE_DEFAULT:
      return { ...state, default: action.payload };
    case SET_ON_LOAD: {
      let { languages = [], default: defaultLang = "" } = action.payload;

      if (languages.length > 0 && defaultLang) {
        return { ...action.payload };
      } else {
        return { ...state };
      }
    }
    default:
      return { ...state };
  }
};

export default Languages;
