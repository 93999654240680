import axios from "axios";

const CurrentVersion = "v0.6.2";

//Development
// const fileUrl = "https://tproduct.ordrio.com/public/";

// const orderServiceApi = axios.create({
//   baseURL: "https://torder.ordrio.com",
// });
// const ServiceApi = axios.create({
//   baseURL: "https://tservice.ordrio.com",
// });
// const UserServiceApi = axios.create({
//   baseURL: "https://tuser.ordrio.com",
// });
// const productServiceApi = axios.create({
//   baseURL: "https://tproduct.ordrio.com",
// });
// const storeServiceApi = axios.create({
//   baseURL: "https://tstore.ordrio.com",
// });
// const customerServiceApi = axios.create({
//   baseURL: "https://tcustomer.ordrio.com",
// });
// const dashboardServiceApi = axios.create({
//   baseURL: "https://tdashboard.ordrio.com",
// });
// const settingServiceApi = axios.create({
//   baseURL: "https://tsetting.ordrio.com",
// });
// const dispatcherServiceApi = axios.create({
//   baseURL: "https://tdispatch.ordrio.com",
// });
// const locationServiceApi = axios.create({
//   baseURL: "https://tlocation.ordrio.com",
// });
// const ImportCsvService = axios.create({
//   baseURL: "https://tredis.ordrio.com",
// });
// const accountServiceApi = axios.create({
//   baseURL: "https://taccounts.ordrio.com",
// });
// const subscriptionService = axios.create({
//   baseURL: "https://tsubs.ordrio.com",
// });
// const notificationService = axios.create({
//   baseURL: "https://tnotification.ordrio.com",
// });

//Beta or Testing
// const fileUrl = "https://dproduct.ordrio.com/public/";
// const orderServiceApi = axios.create({
//   baseURL: "https://dorder.ordrio.com",
// });
// const ServiceApi = axios.create({
//   baseURL: "https://dservice.ordrio.com",
// });
// const UserServiceApi = axios.create({
//   baseURL: "https://duser.ordrio.com",
// });
// const productServiceApi = axios.create({
//   baseURL: "https://dproduct.ordrio.com",
// });
// const storeServiceApi = axios.create({
//   baseURL: "https://dstore.ordrio.com",
// });
// const customerServiceApi = axios.create({
//   baseURL: "https://dcustomer.ordrio.com",
// });
// const dashboardServiceApi = axios.create({
//   baseURL: "https://ddashboard.ordrio.com",
// });
// const settingServiceApi = axios.create({
//   baseURL: "https://dsetting.ordrio.com",
// });
// const dispatcherServiceApi = axios.create({
//   baseURL: "https://ddispatch.ordrio.com",
// });
// const locationServiceApi = axios.create({
//   baseURL: "https://dlocation.ordrio.com",
// });
// const ImportCsvService = axios.create({
//   baseURL: "https://dredis.ordrio.com",
// });
// const accountServiceApi = axios.create({
//   baseURL: "https://daccounts.ordrio.com",
// });
// const subscriptionService = axios.create({
//   baseURL: "https://dsubs.ordrio.com",
// });
// const notificationService = axios.create({
//   baseURL: "https://dnotification.ordrio.com",
// });

//Production
const fileUrl = "https://product.ordrio.com/public/";
const orderServiceApi = axios.create({
  baseURL: "https://order.ordrio.com",
});
const ServiceApi = axios.create({
  baseURL: "https://service.ordrio.com",
});
const UserServiceApi = axios.create({
  baseURL: "https://user.ordrio.com",
});
const productServiceApi = axios.create({
  baseURL: "https://product.ordrio.com",
});
const storeServiceApi = axios.create({
  baseURL: "https://store.ordrio.com",
});
const customerServiceApi = axios.create({
  baseURL: "https://customer.ordrio.com",
});
const dashboardServiceApi = axios.create({
  baseURL: "https://dashboard.ordrio.com",
});
const settingServiceApi = axios.create({
  baseURL: "https://setting.ordrio.com",
});
const dispatcherServiceApi = axios.create({
  baseURL: "https://dispatch.ordrio.com",
});
const locationServiceApi = axios.create({
  baseURL: "https://location.ordrio.com",
});
const ImportCsvService = axios.create({
  baseURL: "https://redis.ordrio.com",
});
const accountServiceApi = axios.create({
  baseURL: "https://accounts.ordrio.com",
});
const subscriptionService = axios.create({
  baseURL: "https://subs.ordrio.com",
});
const notificationService = axios.create({
  baseURL: "https://notification.ordrio.com",
});

export {
  fileUrl,
  orderServiceApi,
  productServiceApi,
  storeServiceApi,
  customerServiceApi,
  dashboardServiceApi,
  settingServiceApi,
  ServiceApi,
  dispatcherServiceApi,
  UserServiceApi,
  locationServiceApi,
  ImportCsvService,
  accountServiceApi,
  CurrentVersion,
  subscriptionService,
  notificationService,
};
